* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*************************
    Keycloak Override
**************************/
#kc-attempted-username {
    font-size: 20px;
    font-family: inherit;
    font-weight: normal;
    padding-right: 10px;
}

#kc-username {
    text-align: center;
}

#kc-webauthn-settings-form {
    padding-top: 8px;
}

#kc-header {
    display: none;
}

#kc-form-buttons {
    display: flex;
    margin-top: 3rem;
}

#kc-form-buttons .rb-button--secondary {
    margin-left: 0.5rem;
}

/*************************
    Custom Styling
**************************/
/* Form Group */
.form-group {
    position: relative;
    margin: 1rem 0;
}

.form-group input {
    margin: .5rem 0;
    outline: none;
}

.formOptions {
    margin-top: 1rem;
}

/* Alert */
.center-alert-text {
    padding: 1rem;
    margin: 0 auto;
    text-align: center;
}

.alert {
    display: flex;
    align-items: center;
    font-family: BoschSansLight;
    font-size: 1rem;
    margin: 2rem auto 0 auto;
    width: 100%;
    min-height: 3rem;
}

.alert-error-message {
    font-style: normal;
    margin: 0.25rem 1rem 0.25rem 0.375rem;
}

.alert-success {
    position: relative;
    background-color: #b8efc9;
    color: #000000;
}

.alert-warning {
    color: #fcaf17;
}

.alert-error {
    position: relative;
    background-color: #ffd9d9;
    color: #000000;

}

.alert-info {
    position: relative;
    background-color: #d1e4ff;
    color: #000000;
}

.alert-icon {
    font-size: 1.5rem;
    margin: 0.75rem 0.5rem 0.75rem 0.75rem;
}

.alert-icon-label {
    font-size: 1.5rem;
    margin: 0.25rem 0.375rem 0.25rem 0.75rem;
}

/* Input */
.input {
    position: relative;
    width: 100%;
    height: 3rem;
    font-family: BoschSansRegular;
    font-size: 1rem;
    font-weight: 300;
    color: black;
    outline: 0;
    border: 0;
    box-shadow: 0 1px 0 0 #000000;
    transition: box-shadow 150ms ease-out;
    padding: 1.5rem 0 1rem 1rem;
    margin: .5rem 0 .5rem 0;
}

.input:focus {
    background-color: #EFEFF0;
    caret-color: #008ECF;
    box-shadow: 0 1px 0 0 #000000;
}

.input.error, div.rb-select.error > div.select-selected {
    box-shadow: 0 1px 0 0 #000000;
    transition: box-shadow 150ms ease-out;
    margin-bottom: 0rem;
    border: 0;
}

.input:disabled {
    color: #BFC0C2;
    background-color: transparent;
}

.input:disabled + .inputLabel {
    color: #BFC0C2;
    background-color: transparent;
}

input::placeholder {
    color: #71767c; /* Bosch Gray 50 */
    opacity: 1; /* Firefox */
}

input::-ms-input-placeholder { /* Microsoft Edge */
    color: #71767c;
}

ul:not(.a-list) li:before{
    height: 0 !important;
}

ol.hide-numbers{
    padding-left: 0;
}

ol.hide-numbers li:before{
    display: none;
}

.defaultLabel {
    font-size: 1rem;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.beforeHelpText {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
}

.afterHelpText {
    margin-top: 0.5rem;
    font-size: 0.9rem;
}

.inputLabel {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0.5rem;
    font-family: BoschSansRegular;
    font-size: 12px;
    color: black;
    background-color: transparent;
    margin: 0.25rem 1rem auto;
    z-index: 10;
    transition: transform 250ms ease-out, font-size 250ms ease-out;
}

.focused .inputLabel ~ .input {
    padding: 1rem 0 .5rem 1rem;
}

.label.disabled {
    color: #BFC0C2;
}

.inputGroup {
    position: relative;
    margin-bottom: 1.5rem;

    .a-checkbox {
        margin-bottom: 0.5rem;
    }

    .a-checkbox input[type=checkbox] {
        float: left;
    }

    .a-radio-button {
        margin-right: 1rem;
        margin-bottom: 0.5rem;
    }
}

/* Other */
.control-label {
    font-family: BoschSansLight, serif;
}

.card-rb .instruction {
    margin: 1rem 0;
}

.subtitle {
    text-align: right;
    margin-top: 30px;
}

.required {
    color: #CB2915;
    display: contents;
}

.kc-feedback-text {
    margin: 0.75rem 1rem 0.75rem 0.5rem;
}

.bold {
    font-weight: bold;
}